import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/enviroments/enviroment';
import { CryptoService } from '@services/crypto.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private url: string = environment.urlApi;


  constructor(
    private http: HttpClient,
    private crypto: CryptoService
  ) { }

  login(payload: string) {
    const scope = `${this.url}/authentication/login`;
    return this.http.post(`${scope}`, { uid: payload })
  }


  get_user_data() {
    const user = localStorage.getItem('user');
    if (user) {
      const data = this.crypto.decryptData(user);
      return JSON.parse(data);
    } else {
      return "no va";
    }
  }
}
