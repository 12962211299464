import { Injectable } from '@angular/core';
import { environment } from '@env/enviroment.prod';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  private key: string = environment.ckKey;

  constructor() { } 

  encryptData(data: string): string {
    return CryptoJS.AES.encrypt(data, this.key).toString();
  }

  decryptData(ciphertext: string): string {
    const bytes = CryptoJS.AES.decrypt(ciphertext, this.key);
    try {
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch (e) {
      console.error('Error decrypting data:', e);
      return '';
    }
  }
}
