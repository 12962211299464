import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/enviroments/enviroment';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token_web = localStorage.getItem('token_web');
    const api_key: string = environment.X_API_KEY;
    const headers = new HttpHeaders({
      'X-API-KEY': api_key,
      'X-TOKEN': `${token_web}`, 
      'ERROR_API_INTERCEPTOR': 'true'
    });

    const reqClone = request.clone({
      headers
    });

    return next.handle(reqClone);
  }
}
